<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/speicherstadt.jpg')" />
      <div class="container">
        <div class="photo-container">
          <img src="img/jens.jpg" alt="">
        </div>
        <h3 class="title">
          Dr. Jens Christian Trapp
        </h3>
        <p class="category">
          Software Architect, Hobby Photograph
        </p>
        <!--div class="content">
          <div class="social-description">
            <h2>26</h2>
            <p>Comments</p>
          </div>
          <div class="social-description">
            <h2>26</h2>
            <p>Comments</p>
          </div>
          <div class="social-description">
            <h2>48</h2>
            <p>Bookmarks</p>
          </div>
        </div-->
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="button-container">
          <a href="mailto:jens@trapp.hamburg" class="btn btn-primary btn-round btn-lg">Nachricht</a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter">
            <i class="fab fa-twitter" />
          </a>
          <a
            href="https://www.instagram.com/trajenc"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Instagram">
            <i class="fab fa-instagram" />
          </a>
        </div>
        <h3 class="title">
          Über mich
        </h3>
        <h5 class="description">
          Software Architect and Developer, Geocaching, Photographie, Radfahren, Gadgets, Hamburg
        </h5>
        <h3 class="title">
          Über diese Seite
        </h3>
        <h5 class="description">
          based on Now UI Kit designed by Invision, coded by BinarCode and Creative Tim.
        </h5>

        <!--div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <h4 class="title text-center">
              Meine Hobbies
            </h4>
          </div>
          <tabs
            pills
            class="nav-align-center"
            tab-content-classes="gallery"
            tab-nav-classes="nav-pills-just-icons"
            type="primary">
            <tab-pane title="Profile">
              <i slot="label" class="now-ui-icons design_image" />

              <div class="col-md-10 ml-auto mr-auto">
                <div class="row collections">
                  <div class="col-md-6">
                    <img src="img/bg6.jpg" class="img-raised">
                    <img src="img/bg11.jpg" alt="" class="img-raised">
                  </div>
                  <div class="col-md-6">
                    <img src="img/bg7.jpg" alt="" class="img-raised">
                    <img src="img/bg8.jpg" alt="" class="img-raised">
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane title="Home">
              <i slot="label" class="now-ui-icons location_world" />

              <div class="col-md-10 ml-auto mr-auto">
                <div class="row collections">
                  <div class="col-md-6">
                    <img src="img/bg1.jpg" alt="" class="img-raised">
                    <img src="img/bg3.jpg" alt="" class="img-raised">
                  </div>
                  <div class="col-md-6">
                    <img src="img/bg8.jpg" alt="" class="img-raised">
                    <img src="img/bg7.jpg" alt="" class="img-raised">
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane title="Messages">
              <i slot="label" class="now-ui-icons sport_user-run" />

              <div class="col-md-10 ml-auto mr-auto">
                <div class="row collections">
                  <div class="col-md-6">
                    <img src="img/bg3.jpg" alt="" class="img-raised">
                    <img src="img/bg8.jpg" alt="" class="img-raised">
                  </div>
                  <div class="col-md-6">
                    <img src="img/bg7.jpg" alt="" class="img-raised">
                    <img src="img/bg6.jpg" class="img-raised">
                  </div>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </div-->
      </div>
    </div>
  </div>
</template>
<script>
// import { Tabs, TabPane } from '@/components';

export default {
  name: 'Profile',
  bodyClass: 'profile-page',
  /* components: {
    Tabs,
    TabPane,
  }, */
};
</script>
<style></style>
