<template>
  <div class="home">
    <slideshow :images="images" />
  </div>
</template>

<script>
// @ is an alias to /src
import Slideshow from '../components/Slideshow';

const exhibition = {
  '2019_0724_13024000.jpg': 'Djuma, Südafrika August 2019',
  'JCTF3087.jpg  JCTF4756.jpg': 'Djuma, Südafrika August 2019',
  '2019_0725_08285500.jpg': 'Djuma, Südafrika August 2019',
  'JCTF3226.jpg': 'Djuma, Südafrika August 2019',
  '2019_0721_12113100.jpg': 'Klaserie Private Nature Reserve, Südafrika August 2019',
  '_DSF0017.jpg': 'Im Wittmoor bei Hamburg, Oktober 2020',
  'JCTF5176.jpg': 'Im Wittmoor bei Hamburg',
  '_DSF8474.jpg': 'Uhu-Nachwuchs im Duvenstedter Brook 2020',
  '_DSF9575.jpg': 'Die Hirschbrunft im Duvenstedter Brook, Oktober 2020',
  '_DSF9615.jpg': 'Die Hirschbrunft im Duvenstedter Brook, Oktober 2020',
  'JCTF4734.jpg': 'Eröffnung der Elphi',
  'JCTF6533-001-EFFECTS.jpg': 'Blick über das Seewetteramt, Hamburg',
  'IMG_20170411_155147_731.jpg': 'Wasserturm in Hamburg Lokstedt',
  'JCTF1823.jpg': 'Am Bubendey-Ufer im Hamburger Hafen',
  'JCTF1846.jpg': 'Am Bubendey-Ufer im Hamburger Hafen',
  '_DSF8750.jpg': 'Im Hafen',
};

export default {
  components: { Slideshow },

  data: () => ({ images: exhibition }),
};
</script>

<style>
.vue-flux .flux-caption.visible {
  position: absolute;
  bottom: -14px;
}
</style>
