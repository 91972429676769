<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor">
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="#/Profile">
              Über mich
            </a>
          </li>
          <li>
            <a href="www.trapp.hamburg/Impressum.html">
              Impressum
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Dr. Jens Trapp
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return { year: new Date().getFullYear() };
  },
};
</script>
<style></style>
