<template>
  <vue-flux
    ref="slider"
    :options="options"
    :images="imageArray"
    :transitions="transitions"
    :captions="captions">
    <template v-slot:preloader>
      <flux-preloader />
    </template>

    <template v-slot:caption>
      <flux-caption />
    </template>

    <template v-slot:controls>
      <flux-controls />
    </template>

    <template v-slot:pagination>
      <flux-pagination />
    </template>

    <template v-slot:index>
      <flux-index />
    </template>
  </vue-flux>
</template>

<script>
// @ is an alias to /src
import { VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPagination,
  FluxPreloader } from 'vue-flux';

const transitions = [
  'fade',
  'fall',
  'cube',
  'book',
  'wave',
  'blocks1',
  'blocks2',
  'camera',
  'concentric',
  'explode',
  'round1',
  'round2',
  'slide',
  'swipe',
  'warp',
  'waterfall',
  'zip',
];

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export default {
  components: {
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader,
  },
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return { autoplay: true };
    },
    imageArray() {
      return Object.keys(this.images).map(s => `${process.env.BASE_URL}img/Ausstellung2020/${s}`);
    },
    transitions() {
      return Object.keys(this.images).map((s, index) => transitions[getRandomInt(index)]);
    },
    captions() {
      return Object.values(this.images).map(s => s);
    },
  },
};
</script>
