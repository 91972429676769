import Vue from 'vue';
import Router from 'vue-router';
import Index from '../pages/Index.vue';
import Home from '../pages/Home.vue';
import Landing from '../pages/Landing.vue';
import Login from '../pages/Login.vue';
import Profile from '../pages/Profile.vue';
import MainNavbar from '../layout/MainNavbar.vue';
import MainFooter from '../layout/MainFooter.vue';

Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      components: {
        default: Home, header: MainNavbar, footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/old',
      name: 'index',
      components: {
        default: Index, header: MainNavbar, footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/landing',
      name: 'landing',
      components: {
        default: Landing, header: MainNavbar, footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: { header: { colorOnScroll: 400 } },
    },
    {
      path: '/profile',
      name: 'profile',
      components: {
        default: Profile, header: MainNavbar, footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
