<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto">
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        Start
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover">
        <div class="popover-body">
          Back to Homepage
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <drop-down
        tag="li"
        title="Alben"
        icon="now-ui-icons design_app"
        class="nav-item">
        <nav-link to="/">
          <i class="now-ui-icons business_chart-pie-36" /> Ausstellung 2o2o
        </nav-link>
      </drop-down>
      <!--drop-down
        tag="li"
        title="Examples"
        icon="now-ui-icons design_image"
        class="nav-item">
        <nav-link to="/landing">
          <i class="now-ui-icons education_paper" /> Landing
        </nav-link>
        <nav-link to="/login">
          <i class="now-ui-icons users_circle-08" /> Login
        </nav-link>
        <nav-link to="/profile">
          <i class="now-ui-icons users_single-02" /> Profile
        </nav-link>
      </drop-down-->

      <!--li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Twitter"
          data-placement="bottom"
          href="https://twitter.com/CreativeTim"
          target="_blank">
          <i class="fab fa-twitter" />
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li-->
      <!--li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/CreativeTim"
          target="_blank">
          <i class="fab fa-facebook-square" />
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li-->
      <!--li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/trajenc"
          target="_blank">
          <i class="fab fa-instagram" />
          <p>Instagram</p>
        </a>
      </li-->
      <!--li class="nav-item">
        <a
          class="nav-link"
          href="https://www.heureca.net"
          target="_blank">
          <i class="now-ui-icons arrows-1_cloud-download-93" />
          <p>Geocaching</p>
        </a>
      </li-->
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';

export default {
  name: 'MainNavbar',
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover,
  },
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
};
</script>

<style scoped></style>
