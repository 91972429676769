<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg6.jpg')" />
      <div class="content-center">
        <div class="container">
          <h1 class="title">
            This is our great company.
          </h1>
          <div class="text-center">
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-facebook-square" />
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-twitter" />
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              Who we are?
            </h2>
            <h5 class="description">
              According to the National Oceanic and Atmospheric Administration,
              Ted, Scambos, NSIDClead scentist, puts the potentially record low
              maximum sea ice extent tihs year down to low ice extent in the
              Pacific and a late drop in ice extent in the Barents Sea.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary" />
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/login.jpg')">
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "Over the span of the satellite record, Arctic sea ice has
                  been declining significantly, while sea ice in the
                  Antarctichas increased very slightly"
                  <br>
                  <br>
                  <small>-NOAA</small>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/bg3.jpg')" />
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/bg1.jpg')" />
              <h3>
                So what does the new record for the lowest level of winter ice
                actually mean
              </h3>
              <p>
                The Arctic Ocean freezes every winter and much of the sea-ice
                then thaws every summer, and that process will continue whatever
                happens with climate change. Even if the Arctic continues to be
                one of the fastest-warming regions of the world, it will always
                be plunged into bitterly cold polar dark every winter. And
                year-by-year, for all kinds of natural reasons, there’s huge
                variety of the state of the ice.
              </p>
              <p>
                For a start, it does not automatically follow that a record
                amount of ice will melt this summer. More important for
                determining the size of the annual thaw is the state of the
                weather as the midnight sun approaches and temperatures rise.
                But over the more than 30 years of satellite records, scientists
                have observed a clear pattern of decline, decade-by-decade.
              </p>
              <p>
                The Arctic Ocean freezes every winter and much of the sea-ice
                then thaws every summer, and that process will continue whatever
                happens with climate change. Even if the Arctic continues to be
                one of the fastest-warming regions of the world, it will always
                be plunged into bitterly cold polar dark every winter. And
                year-by-year, for all kinds of natural reasons, there’s huge
                variety of the state of the ice.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">
          Here is our team
        </h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised">
                <h4 class="title">
                  Romina Hadid
                </h4>
                <p class="category text-primary">
                  Model
                </p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo"
                   class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter" /></a>
                <a href="#pablo"
                   class="btn btn-primary btn-icon btn-round"><i class="fab fa-instagram" /></a>
                <a href="#pablo"
                   class="btn btn-primary btn-icon btn-round"><i class="fab fa-facebook-square" /></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/ryan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised">
                <h4 class="title">
                  Ryan Tompson
                </h4>
                <p class="category text-primary">
                  Designer
                </p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo"
                   class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter" /></a>
                <a href="#pablo"
                   class="btn btn-primary btn-icon btn-round"><i class="fab fa-linkedin" /></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/eva.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised">
                <h4 class="title">
                  Eva Jenner
                </h4>
                <p class="category text-primary">
                  Fashion
                </p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo"
                   class="btn btn-primary btn-icon btn-round"><i class="fab fa-google-plus" /></a>
                <a href="#pablo"
                   class="btn btn-primary btn-icon btn-round"><i class="fab fa-youtube" /></a>
                <a href="#pablo"
                   class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">
          Want to work with us?
        </h2>
        <p class="description">
          Your project is very important to us.
        </p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              v-model="form.firstName"
              class="input-lg"
              placeholder="First Name..."
              addon-left-icon="now-ui-icons users_circle-08" />
            <fg-input
              v-model="form.email"
              class="input-lg"
              placeholder="Email Here..."
              addon-left-icon="now-ui-icons ui-1_email-85" />
            <div class="textarea-container">
              <textarea
                v-model="form.message"
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                placeholder="Type a message..." />
            </div>
            <div class="send-button">
              <n-button type="primary"
                        round
                        block
                        size="lg">
                Send Message
              </n-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';

export default {
  name: 'Landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: '',
      },
    };
  },
};
</script>
<style></style>
